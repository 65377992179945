import { useState } from "react";
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';

export const AboutFOrm = () => {
    const form = useRef();
    const FormType = 'AboutForm';
    const initialUser = {
        FirstName: '',
        Phone: '',
        Email: '',
        Message: '',
    };
    const [formSubmitting, setFormSubmitting] = useState(false);
    const [formMessage, setFormMessage] = useState('');
    const [formData, setFormData] = useState(initialUser);
    const [Fname, setFName] = useState(formData.FirstName);
    const [Phone, setPhoneNumber] = useState(formData.Phone);
    const [Email, setEmail] = useState(formData.Email);
    const [Message, setMessage] = useState(formData.Message);

    const sendEmail = async (e) => {
        setFormSubmitting(true);
        e.preventDefault();
        setFormData({ Fname: Fname, Phone: Phone, Email: Email, Message: Message, FormType: FormType});

        const option = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                Fname , Phone, Email, Message, FormType
            })
        };
        const res = await fetch(`${process.env.REACT_APP_FIREBASE_DATABASE_URL}/AboutUs-Form-Entries.json`, option);
        if (res.ok) {
            emailjs.sendForm('service_fn3vix4', 'template_uz9z7nr', form.current, '-bWN5Fc81jOgMZsJs')
            .then((result) => {
                setFormMessage({formMessage : 'Submitted'})
                setFormSubmitting(false)
                setFName('')
                setPhoneNumber('')
                setEmail('')
                setMessage('')
            }, (error) => {
                setFormMessage({formMessage : 'SubmittedError'})
            });
        }
    };

    return (
        <div className="About-form w-100 p-md-5 p-3 rounded login-box bg-light">
            <div className={`row d-flex justify-content-center ${formSubmitting === false ? '' : 'loading'}`}>
                <h2 className="h2 text-dark text-center">Contact Us</h2>
                <p className="p text-dark text-center mb-5">Having any issue feel free to contact Us.</p>
                <div className="col-12 col-md-5">
                    <form method="POST" ref={form} onSubmit={sendEmail}>
                        <div className="row">
                            <div className="col-12 mb-3">
                                <div className="form-group ">
                                    <input
                                        type="text"
                                        placeholder="Enter Your Name"
                                        className="form-control mb-2 text-center bg-light"
                                        id="FName"
                                        value={Fname}
                                        name="First_Name"
                                        autoComplete="Don"
                                        onChange={(e) => setFName(e.target.value)}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="col-12 mb-3">
                                <div className="form-group">
                                    <input
                                        type="number"
                                        placeholder="***********"
                                        className="form-control mb-2 text-center bg-light"
                                        id="phone"
                                        value={Phone}
                                        name="phone"
                                        autoComplete="***********"
                                        onChange={(e) => setPhoneNumber(e.target.value)}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="col-12 mb-3">
                                <div className="form-group">
                                    <input
                                        type="email"
                                        placeholder="Enter Your Email"
                                        className="form-control mb-2 text-center bg-light"
                                        id="email"
                                        value={Email}
                                        name="email"
                                        autoComplete="test@gmail.com"
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="col-12 mb-4">
                                <div className="form-group">
                                    <textarea
                                        type="text"
                                        placeholder="Enter Message Here"
                                        value={Message}
                                        name="message"
                                        className="form-control mb-2 text-center bg-light"
                                        id="message"
                                        cols="30"
                                        rows="6"
                                        onChange={(e) => setMessage(e.target.value)}
                                        required
                                    ></textarea>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <input
                                        type="hidden"
                                        value={FormType}
                                        name="FormType"
                                    />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group d-flex justify-content-center mb-4">
                                    <button type="submit" className="w-50 bg-transparent border-0">
                                        <div className="d-block py-3 text-center w-75 m-auto anim-button text-dark">
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                            Submit
                                        </div>
                                    </button>
                                    <div className="submitting sf-hidden"></div>
                                </div>
                                <p className={`p text-center ${formMessage? 'submitted-success' : formMessage? 'SubmittedError' : 'd-none'}`}>
                                    {formMessage? 'Form Has Been Submit Succesfully' : formMessage? 'Form Has Not Submit Succesfully' : ''}</p>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};