import Typewriter from '../../TypeWritter/TypeWritter.jsx';
export const AboutBanner = () => {
    return(
        <div className="banner-section d-flex align-items-center">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-7">
                        <h1 className="text-white">About Us</h1>
                        <p className="p">
                            <Typewriter text="At EgeneusCo, we pioneer bespoke software solutions that propel businesses forward, harnessing the power of technology to fuel innovation and growth. Our team of visionary experts crafts tailored applications that drive efficiency, success, and transformation." delay={120} />    
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}