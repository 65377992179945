import React, { useState } from "react";
import Logo from '../../asserts/EgeniusLogo.webp';
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';

export const FooterWidget2 = () => {
    const Route = [
        {
          path: "/services",
          name: "Services",
        },
        {
          path: "/projects",
          name: "Projects",
        },
        {
          path: "/products",
          name: "Products",
        },
        {
          path: "/contact-us",
          name: "Contact Us",
        },
      ];
      const [routeDate] = useState(Route);
      const location = useLocation();
    return(
        <div className="col-12 col-md-6 col-lg-4 col-xl-5 mb-4 d-flex flex-column align-items-center align-items-md-start gap-4">
            <div className="" style={{width: "130px"}}>
              <Link to="/" className="link text-decoration-none text-white">
                <img src={Logo} alt="footer" height="100%" width="100%" className="d-block image-fluid w-100 h-100" loading="lazy"/>
              </Link>
                
            </div>
            <div className="py-4">
                <ul className="list-unstyled d-flex flex-row flex-wrap gap-3 m-0"  style={{fontSize: "18px"}}>
                    {routeDate.map(({ name, path }, index) => {
                        return (
                            <li key={index} className={location.pathname === path ? 'active' : ''}>
                                <Link to={path} className="link text-decoration-none text-white">{name}</Link>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    )
}