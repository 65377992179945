import {FaSkype , FaEnvelopeOpenText , FaGlobe , FaWhatsapp } from 'react-icons/fa';
export const ContactUsDetails = () => {
    return(
        <div className="row gap-3 d-flex flex-wrap justify-content-center p-3 w-100 mb-4">
            <div className="col-12 col-md-5 text-center  d-none d-md-flex align-items-center py-5 px-2 card gap-2">  
                <FaEnvelopeOpenText color="#7BA447" className='mb-3' style={{height:"50px", width:"50px"}}/>
                <h3><a href="mailto:info@egeneus.co"  rel="noreferrer" aria-label='mail' >EMAIL US</a></h3>
            </div>
            <div className="col-12 col-md-5 text-center d-flex align-items-center py-5 px-2 card gap-2">
                <FaWhatsapp color="#7BA447" className='mb-3' style={{height:"50px", width:"50px"}}/>
                <h3><a href="https://wa.me/+447404237566"  rel="noreferrer" aria-label='phone'>CONTACT US</a></h3>
            </div>
            <div className="col-12 col-md-5 text-center d-none d-md-flex align-items-center py-5 px-2 card gap-2">
                <FaSkype color="#7BA447" className='mb-3' style={{height:"50px", width:"50px"}}/>
                <h3><a href="skype:live:.cid.b4dbaeeacb56ba30?chat" rel="noreferrer" aria-label='landline'>SKYPE</a></h3>
            </div>
            <div className="col-12 col-md-5 text-center d-none d-md-flex align-items-center py-5 px-2 card gap-2">
                <FaGlobe color="#7BA447" className='mb-3' style={{height:"50px", width:"50px"}}/>
                <h3><a href="https://maps.app.goo.gl/WCjgbgGNHdT67DMz9"  rel="noreferrer" aria-label='location' target="_blank">OUR OFFICE</a></h3>
            </div>
        </div>
    )
}