import { useEffect } from "react";
import { Link } from "react-router-dom";
import ScrollToTop from '../component/ScrollToTop/ScrollToTop';
import withLayout from '../component/withLayout/withLayout';

const TermsCondition = () => {
    ScrollToTop();
    useEffect(() => {
		const link = document.createElement('link');
		link.rel = 'canonical';
		link.href = 'https://egeneus.co/terms-conditions';
		document.head.appendChild(link);
		return () => {
		  document.head.removeChild(link);
		};
	}, []);
    return(
        <div id="terms-condition" className="wrapper terms-conditions py-4 py-lg-5 my-lg-5">
            <div className="container">
                <h1 className="h1 mb-5 text-dark">Terms and Conditions of EgeneusCo.</h1>
                <h2 className="h2 text-dark">1. Privacy Policy</h2>
                <p className="p text-dark">EgeneusCo website is also governed by our Privacy Policy, which can be found at <Link to='/privacy-policy' className="link text-decoration-underline text-dark">Privacy & Policy</Link>.</p>
                <h2 className="h2 text-dark">2. Intellectual Property</h2>
                <p className="p text-dark">All content and materials within the Website are the property of EgeneusCo and are protected by intellectual property laws.</p>
                <h2 className="h2 text-dark">3. Limitation of Liability</h2>
                <p className="p text-dark">EgeneusCo is not liable for any damages, direct or indirect, arising from the use of the Website.</p>
                <h2 className="h2 text-dark">4. Changes to Terms</h2>
                <p className="p text-dark">EgeneusCo may update these terms at any time. Users are responsible for checking for updates.</p>
                <h2 className="h2 text-dark">5. Governing Law</h2>
                <p className="p text-dark">These Terms and Conditions are governed by and construed in accordance with the laws of the Pakistan.</p>
                <h2 className="h2 text-dark">6. Contact Information</h2>
                <p className="p text-dark">For any questions regarding these terms, please visit our <Link to='/contact-us' className="text-dark text-decoration-underline">Contact Us</Link> page.</p>
            </div>
        </div>
    )
}
export default withLayout(TermsCondition)