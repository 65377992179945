import { HomeProjectsCards } from "../Cards/HomeProjectsCards"
import { Link } from "react-router-dom";

export const Advisory = () => {
    return(
        <div className="Advisory-section py-4 py-lg-5">
            <div className="container p-0">
                <div className="mb-lg-4 text-center">
                    <h2 className="text-dark fade-out">Our Related Projects</h2>
                    <p className="text-dark text-center">Explore our collection of innovative projects designed to inspire and solve real-world challenges. </p>
                </div>
                <HomeProjectsCards/>
                <div className="text-center text-decoration-none m-auto mt-lg-4 py-2">
                  <Link to="/projects" className="link btn btn-primary form-button">View More</Link>
                </div>
            </div>
        </div>
    )
}