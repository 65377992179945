import { useEffect } from "react";
import { AboutBanner } from "../component/AboutComponent/AboutBanner";
import { AboutService } from "../component/AboutComponent/AboutService";
import { AboutTeam } from "../component/AboutComponent/AboutTeam";
import { AboutFOrm } from "../component/AboutComponent/AboutFOrm";
import { AboutCompany } from "../component/AboutComponent/AboutCompnay";
import { HomeAccordian } from "../component/HomeComponent/HomeAccordian";
import { Testimonials } from "../component/Testimonial/Testimonials";
import ScrollToTop from '../component/ScrollToTop/ScrollToTop';
import withLayout from '../component/withLayout/withLayout';

const AboutUs = () => {
  ScrollToTop();
  useEffect(() => {
    const link = document.createElement('link');
    link.rel = 'canonical';
    link.href = 'https://egeneus.co/about-us';
    document.head.appendChild(link);
    return () => {
      document.head.removeChild(link);
    };
  }, []);

  return (
    <div id="About-page" className="About-wrapper">
      <AboutBanner/>
      <AboutService/>
      <AboutTeam/>
      <AboutCompany/>
      <AboutFOrm/>
      <HomeAccordian/>
      <Testimonials/>
    </div>
  );
};

export default withLayout(AboutUs);