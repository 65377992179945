import { useEffect } from "react";
import { BlogBanner } from "../component/BlogComponent/BlogBanner";
import { BlogCards } from "../component/Cards/BlogCards";
import ScrollToTop from '../component/ScrollToTop/ScrollToTop';
import withLayout from '../component/withLayout/withLayout';

const Blogs = () => {
  ScrollToTop();

  useEffect(() => {
    const link = document.createElement('link');
    link.rel = 'canonical';
    link.href = 'https://egeneus.co/blogs';
    document.head.appendChild(link);
    return () => {
      document.head.removeChild(link);
    };
  }, []);

  return (
    <div id="blogs-page" className="About-wrapper">
      <BlogBanner/>
      <div className="blogs-details py-4 py-lg-5">
        <BlogCards/>
      </div>  
    </div>
  );
};

export default withLayout(Blogs)