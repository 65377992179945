import {TestimonialsItems} from '../Items/TestimonialsItems'

export const Testimonials = () => {
    return(
        <div className="testi-section bg-light py-4 py-lg-5">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div id="Testimonials" className="text-center py-4 py-lg-4">
                            <div id="carouselExampleIndicators" className="carousel slide">
                                <div className="carousel-inner">
                                    {
                                        TestimonialsItems.slice(0, TestimonialsItems.length).map((data, index) =>(
                                            <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                                                <div className="">
                                                    <img src={data.image} alt="testi-img" height="200px" width="200px"  className="rounded-circle mb-4" loading="lazy"/>
                                                    <div className="row d-flex justify-content-center">
                                                        <div className="col-lg-8">
                                                            <h3 className="mb-3 text-dark text-center">{data.name}</h3>
                                                            <p className="text-muted mb-4 text-center">{data.Major}</p>
                                                            <p className="p text-muted text-center" dangerouslySetInnerHTML={{ __html: data.description}}></p>
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Previous</span>
                                </button>
                                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Next</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}