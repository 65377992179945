import Carousel1 from "../../asserts/meet-team1.webp"
import Carousel2 from "../../asserts/meet-team2.webp"

export const TeamsItems = [
    {
        id:'JESSICAANTONY',
        image: Carousel1,
        Name: 'JESSICA ANTONY', 
        Major: 'Seo Expert',
        Experience: '12 Year Experience',
        Profile:'',
    },
    {
        id:'MADELEINEGOLDEN',
        image: Carousel2,
        Name: 'MADELEINE GOLDEN', 
        Major: 'Web Developer',
        Experience: '8 Year Experience',
        Profile:'',
    },

  ];
  
  
  
  
  
  
  