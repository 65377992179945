export const BlogBanner = () => {
    return(
        <div className="blog-banner d-flex align-items-center">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-7">
                        <h1 className="text-white">Related Blogs</h1>
                        <p className="p">Welcome to the EgeneusCo blog, where our experts share insights on front-end, UI/UX, back-end, DevOps, and more. Stay up-to-date with the latest trends, technologies, and best practices in web development.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}