import { FooterSocials } from '../component/Footer/FooterSocial';
import { FooterWidget1 } from "../component/Footer/FooterWidget1";
import { FooterWidget2 } from "../component/Footer/FooterWidget2";
import { Link } from "react-router-dom";

export const Footer = () => {

    return(
        
        <footer id='footer' className="footer py-4 py-lg-5 bg-dark text-white">
            <div className="container">
                <div className="footer-container py-4 py-lg-5">
                    <div className="row">
                        <FooterWidget2/>
                        <FooterWidget1/>
                        <FooterSocials/>
                    </div>
                    <div className='row'>
                        <div className='col-12 col-md-4 text-center text-md-start'>
                            <p className='text-center text-md-start'>EgeneusCo @ 2024</p>
                            <p className='text-center text-md-start'><Link to='/privacy-policy' className="text-decoration-underline">Privacy Policy</Link> . <Link to='/terms-conditions' className="text-decoration-underline">Terms & Conditions</Link>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>


    )
}

                        
                        
