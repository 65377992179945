import {products} from '../Items/ProjectsItems'
import button_view from '../../asserts/button-view.webp'
import React, { useState } from 'react';

export const ProjectsCards = () => {
    const itemsPerPage = 6;
    const [currentPage, setCurrentPage] = useState(1);
    const [activeButton, setActiveButton] = useState('');
    const [SetProduct, setProduct] = useState('');

    const handleButtonClick = (category) => {
        setActiveButton(category);
        setProduct(category);
        setCurrentPage(1);
    };
    
    const filteredProducts = products.filter(item => activeButton === '' ? true : item.type === activeButton);
    const paginatedData = filteredProducts.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
    const handlePageChange = (page) => {
        if (page > 0 && page <= Math.ceil(filteredProducts.length / itemsPerPage)) {
            setCurrentPage(page);
        }
    };

    return(
        <div className="blogs-accordians-container">
            <div className='button-container px-2 mx-1 d-flex gap-4'>
                <button
                    className={activeButton === '' ? 'products_btn products_btn_active' : 'products_btn'}
                    onClick={() => handleButtonClick('')}>
                    ALL Pages/Sites
                </button>
                <button
                    className={activeButton === 'Html' ? 'products_btn products_btn_active' : 'products_btn'}
                    onClick={() => handleButtonClick('Html')}>
                    Html Pages/Sites
                </button>
                <button
                    className={activeButton === 'React' ? 'products_btn products_btn_active' : 'products_btn'}
                    onClick={() => handleButtonClick('React')}>
                    React Pages/Sites
                </button>
                <button
                    className={activeButton === 'Laravel' ? 'products_btn products_btn_active' : 'products_btn'}
                    onClick={() => handleButtonClick('Laravel')}>
                    Laravel Pages/Sites
                </button>
                <button
                    className={activeButton === 'Wordpress' ? 'products_btn products_btn_active' : 'products_btn'}
                    onClick={() => handleButtonClick('Wordpress')}>
                    Wordpress Pages/Sites
                </button>
            </div>
            <div className="Adcard-container row m-auto pt-4 pt-lg-5">   
                <h2 className='text-dark text-center mb-5'> Our {SetProduct} Pages & Sites</h2>
                {
                    products.length > 0 ? (
                        paginatedData.map((data, index) => (
                            <div key={index} className='col-6 col-md-6 col-lg-4 mb-2 mb-lg-5'>
                                <a href={data.link}  rel="noreferrer" aria-label={data.name} target='_blank' className='text-decoration-none'>
                                    <div className="card overflow-hidden text-center rounded bg-transparent text-dark border-0">
                                        <figure className="snip1529">
                                            <div className="image-container">
                                                <img src={data.image} height="100%" width="100%" alt={data.name + "image"} className="m-auto d-block img-fluid w-100 h-100" loading="lazy"/>
                                            </div>
                                            <div className="date"><span className="day text-dark mb-2">{data.price}$</span><span className="month text-dark">Price</span></div>
                                            <figcaption>
                                                <h3>{data.name}</h3>
                                                <p className='text-start'>{data.description}</p>
                                            </figcaption>
                                            <div className="hover">
                                                <img src={button_view} height="30%" width="30%" alt="hover-icon" loading="lazy"/>
                                            </div>
                                        </figure>
                                    </div>
                                </a>
                            </div>
                        ))
                    ) : (
                        <h3 className='text-dark'>No Products found.</h3>
                    )
                }
                {
                    Math.ceil(filteredProducts.length) > 6 ? (
                      
                        <div className='Paginated-Button d-flex flex-row justify-content-center align-items-center gap-4 py-4'>
                            <button onClick={() => handlePageChange(currentPage - 1)} className='products_btn'>Prev</button>
                            <span className='text-dark'>Page {currentPage} of {Math.ceil(filteredProducts.length / itemsPerPage)} </span>
                            <button onClick={() => handlePageChange(currentPage + 1)} className='products_btn'>Next</button>
                        </div>
                    ):(
                       
                        <></>
                    )
                }
            </div>  
        </div>    
    )
}