import Carousel1 from "../../asserts/carousel1.webp"
import Carousel2 from "../../asserts/carousel2.webp"

export const TestimonialsItems = [
    {
        id:'review1',
        image: Carousel1,
        name: 'Maria Kate', 
        Major: 'Photographer',
        description: '"I developed a portfolio website for myself as a photographer to showcase my work. I have captured a lot of photos, and I want to display them all on my website to share with my users. <br>Thank you to the <span class="d-inline">Egeneus.co </span> team for the great experience."',
        type: 'reviews',
        Profile:'https://fashion-co.vercel.app/',
    },
    {
        id:'review2',
        image: Carousel2,
        name: 'Dany joy', 
        Major: 'Busines Man',
        description: '"I am here to share that the <span class="d-inline">Egeneus.co </span> team is very cooperative and experienced. <br>They built the website according to my thoughts and requirements."',
        type: 'reviews',
        Profile:'https://fashion-co.vercel.app/',
    },

  ];
  
  
  
  
  
  
  