import * as React from "react";
import { useState , useRef } from "react";
import { useNavigate } from 'react-router-dom'; 
import emailjs from '@emailjs/browser';

export const ProductContactForm = (props) => {
    const { productData } = props;
    const form = useRef();
    const navigate = useNavigate();
    const FormType = 'ProductForm';

    const initialUser = {
        FirstName: '',
        LastName: '',
        Phone: '',
        Email: '',
        ProductName: productData.name,
        ProductPrice: productData.price,
        Message:""
    };
    const [formdata, setFormData] = useState(initialUser);
    const [step, setStep] = useState(1);
    const [FirstName, setFName] = useState(formdata.FirstName);
    const [LastName, setLName] = useState(formdata.LastName);
    const [Phone, setPhoneNumber] = useState(formdata.Phone);
    const [Email, setEmail] = useState(formdata.Email);
    const [ProductName, setProductName] = useState(productData.name);
    const [ProductPrice, setProductPrice] = useState( productData.price);
    const [Message, setMessage] = useState(formdata.Message);

    const sendEmail = async (e) => {
        e.preventDefault();
        setFormData({
            FirstName: FirstName,
            LastName: LastName,
            Phone: Phone,
            Email: Email,
            ProductName: ProductName,
            ProductPrice: ProductPrice,
            Message:Message,
        });
        const option = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                FirstName , LastName , Phone , Email , ProductName , ProductPrice , Message , FormType
            })
        };

        const res = await fetch(`${process.env.REACT_APP_FIREBASE_DATABASE_URL}/Products-Form-Entries.json`, option);
        if (res.ok) {
            emailjs.sendForm('service_fn3vix4', 'template_uz9z7nr', form.current, '-bWN5Fc81jOgMZsJs')
            .then((result) => {
                navigate('/Product-form-submited');
            }, (error) => {
                alert("Error Sending Message");
            });
        }
    };

    const handleNextStep = () => {
        setStep(step + 1);
    };

    const handlePrevStep = () => {
        setStep(step - 1);
    };

    return(
        <div className="contact-wrap w-100 p-md-5 p-lg-4 rounded login-box">

            <form method="POST"  ref={form} onSubmit={sendEmail}>
                {step === 1 && (
                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <div className="form-group ">
                                <label htmlFor="FName" className="text-dark mb-2">First NAME</label>
                                <input
                                    type="text"
                                    placeholder="First Name" 
                                    className="form-control mb-2" 
                                    id="FName"
                                    value={FirstName}
                                    name="First_Name"
                                    autoComplete="Don"
                                    onChange={(e) => setFName(e.target.value)}
                                    required
                                />
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="form-group">
                                <label htmlFor="LName" className="text-dark mb-2">Last Name</label>
                                <input
                                    type="text"
                                    placeholder="Last Name"
                                    className="form-control mb-2"
                                    id="LName"
                                    value={LastName}
                                    name="Last_Name"
                                    autoComplete="Jon"
                                    onChange={(e) => setLName(e.target.value)}
                                    required
                                />
                            </div>
                        </div>
                        <div className="col-12 mb-3">
                            <div className="form-group">
                                <label htmlFor="phone" className="text-dark mb-2">Phone Number</label>
                                    <input
                                    type="number"
                                    placeholder="***********" 
                                    className="form-control mb-2" 
                                    id="phone"
                                    value={Phone}
                                    name="phone"
                                    autoComplete="***********"
                                    onChange={(e) => setPhoneNumber(e.target.value)}
                                    required
                                />
                            </div>
                        </div>
                        <div className="col-12 mb-3">
                            <div className="form-group">
                                <label htmlFor="email" className="text-dark mb-2">Email</label>
                                    <input
                                    type="email"
                                    placeholder="test@gmail.com" 
                                    className="form-control mb-2" 
                                    id="email"
                                    value={Email}
                                    name="email"
                                    autoComplete="test@gmail.com"
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                            </div>
                        </div>                      
                        <div className="col-md-6 mb-3">
                            <div className="form-group">
                                <label htmlFor="ProductName" className="text-dark mb-2">Product Name</label>
                                <input
                                    type="text"
                                    placeholder="Enter Product Name" 
                                    value={ProductName}
                                    name="ProductName"
                                    className="form-control mb-2" 
                                    id="ProductName"
                                    readOnly
                                    onChange={(e) => setProductName(e.target.value)}
                                ></input>
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="form-group">
                                <label htmlFor="ProductPrice" className="text-dark mb-2">Product Price</label>
                                <input
                                    type="text"
                                    placeholder="Enter Product Price" 
                                    value={`$${ProductPrice}`}
                                    name="ProductPrice"
                                    className="form-control mb-2" 
                                    id="ProductPrice"
                                    readOnly
                                    onChange={(e) => setProductPrice(e.target.value)}
                                ></input>
                            </div>
                        </div>
                        <div className="col-md-12 mb-5">
                            <div className="form-group">
                                <label htmlFor="message" className="text-dark mb-2">MESSAGE</label>
                                <textarea
                                    type="text"
                                    placeholder="Enter Message" 
                                    value={Message}
                                    name="message"
                                    className="form-control mb-2" id="message" cols="30" rows="6"
                                    onChange={(e) => setMessage(e.target.value)}
                                ></textarea>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-group d-flex justify-content-center">
                                <button type="submit" className="w-75 bg-transparent border-0">
                                    <div className="d-block py-3 text-center w-75 m-auto anim-button text-dark">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        Submit
                                    </div>
                                </button>
                                <div className="submitting sf-hidden"></div>
                            </div>
                        </div>
                    </div>
                )}
                {step === 2 && (
                    <div className="row">
                        <div className="col-12 ">
                            <div className="form-group d-flex justify-content-center">
                                <button type="button" onClick={handleNextStep} className="w-75 bg-transparent border-0">
                                    <div className="d-block py-3 text-center w-75 m-auto anim-button text-dark">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        Next
                                    </div>
                                </button>
                                <div className="submitting sf-hidden"></div>
                            </div>
                        </div>
                        <div className="col-12 mb-5">
                            <div className="form-group d-flex justify-content-end">
                                <button type="button"  onClick={handlePrevStep} className="w-25 bg-transparent border rounded">
                                    <div className="d-block text-center m-auto anim-button text-dark">
                                        Prev
                                    </div>
                                </button>
                                <div className="submitting sf-hidden"></div>
                            </div>
                        </div>
                        <div className="col-md-12 mb-5">
                            <div className="form-group">
                                <label htmlFor="message" className="text-dark mb-2">MESSAGE</label>
                                <textarea
                                    type="text"
                                    placeholder="Enter Message" 
                                    value={Message}
                                    name="message"
                                    className="form-control mb-2" id="message" cols="30" rows="6"
                                    onChange={(e) => setMessage(e.target.value)}
                                ></textarea>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <input
                                    type="hidden"
                                    value={FormType}
                                    name="FormType"
                                />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-group d-flex justify-content-center">
                                <button type="submit" className="w-75 bg-transparent border-0">
                                    <div className="d-block py-3 text-center w-75 m-auto anim-button text-dark">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        Submit
                                    </div>
                                </button>
                                <div className="submitting sf-hidden"></div>
                            </div>
                        </div>
                    </div>
                )}
            </form>
        </div>
    )
};