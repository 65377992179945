import {FaFacebook , FaLinkedin , FaCodepen , FaSkype , FaTwitter , FaInstagram , FaYoutube  } from 'react-icons/fa';
import Logo from '../../asserts/EgeniusLogo.webp';
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpwork } from '@fortawesome/free-brands-svg-icons';

export const FooterSocials = () => {
    let [SkypeClass, setSkypeClass] = useState('');
    let [LinkedinClass, setLinkedinClass] = useState('');
    let [CodepenClass, setCodepenClass] = useState('');
    let [UpworkClass, setUpworkClass] = useState('');
    let [RotateClass, setRotateClass] = useState('');
    const handleClick = () => {
        setSkypeClass(SkypeClass === 'showskype' ? '' : 'showskype');
        setLinkedinClass(LinkedinClass === 'showlinkedin' ? '' : 'showlinkedin');
        setCodepenClass(CodepenClass === 'showCodepen' ? '' : 'showCodepen');
        setUpworkClass(UpworkClass === 'showUpwork' ? '' : 'showUpwork');
        setRotateClass(RotateClass === 'Rotation' ? '' : 'Rotation');
    };

    return(
        <div className="col-12 col-md-6 col-lg-4 col-xl-3 mb-4 d-flex align-items-center align-items-md-start align-items-lg-end flex-column">
            <h3>Explore More About </h3>
            <p className='text-center text-md-end mt-3'>@Egeneus.co is a building platform where all the freelancers and startups can grow together.</p>
            <ul className="d-flex flex-row justify-content-start gap-3 list-unstyled my-3">
                <li><a href='https://www.facebook.com/profile.php?id=61560960552247' aria-label='facebook' rel="noreferrer" target="_blank">
                    <div className="icons"><FaFacebook/></div></a>
                </li>
                <li><a href='https://x.com/CoEgenius' aria-label='codepen' rel="noreferrer" target="_blank">
                    <div className="icons"><FaTwitter/></div></a>
                </li>
                <li><a href='https://www.threads.net/@egeneusco' aria-label='codepen' rel="noreferrer" target="_blank">
                    <div className="icons">
                        <svg className="ThreadIcon" xmlns="http://www.w3.org/2000/svg" shapeRendering="geometricPrecision" textRendering="geometricPrecision" imageRendering="optimizeQuality" fillRule="evenodd" clipRule="evenodd" viewBox="0 0 439.999 511.429"><path fillRule="nonzero" d="M342.382 237.037a175.843 175.843 0 00-6.707-3.045c-3.947-72.737-43.692-114.379-110.428-114.805-38.505-.256-72.972 15.445-94.454 48.041l36.702 25.178c15.265-23.159 39.221-28.097 56.864-28.097.203 0 .408 0 .61.003 21.973.139 38.555 6.528 49.287 18.987 7.81 9.071 13.034 21.606 15.62 37.425-19.482-3.312-40.552-4.329-63.077-3.039-63.449 3.656-104.24 40.661-101.5 92.081 1.39 26.083 14.384 48.522 36.586 63.18 18.773 12.391 42.95 18.451 68.078 17.08 33.183-1.819 59.214-14.48 77.376-37.631 13.793-17.579 22.516-40.362 26.368-69.068 15.814 9.543 27.535 22.103 34.007 37.2 11.007 25.665 11.648 67.84-22.764 102.222-30.15 30.121-66.392 43.151-121.164 43.554-60.757-.45-106.707-19.934-136.582-57.914-27.976-35.563-42.434-86.93-42.973-152.675.539-65.745 14.997-117.113 42.973-152.675 29.875-37.979 75.824-57.464 136.581-57.914 61.197.455 107.948 20.033 138.967 58.195 15.21 18.713 26.677 42.248 34.236 69.688l43.011-11.476c-9.163-33.775-23.581-62.881-43.203-87.017C357.031 25.59 298.872.519 223.935 0h-.3C148.851.518 91.343 25.683 52.709 74.794 18.331 118.498.598 179.308.002 255.534l-.002.18.002.18c.596 76.226 18.329 137.037 52.707 180.741 38.634 49.11 96.142 74.277 170.926 74.794h.3c66.487-.462 113.352-17.868 151.96-56.442 50.511-50.463 48.991-113.717 32.342-152.548-11.944-27.847-34.716-50.464-65.855-65.402zm-114.795 107.93c-27.809 1.566-56.7-10.917-58.124-37.652-1.056-19.823 14.108-41.942 59.83-44.577 5.237-.302 10.375-.45 15.422-.45 16.609 0 32.146 1.613 46.272 4.702-5.268 65.798-36.173 76.483-63.4 77.977z"/></svg>
                    </div></a>
                </li>
                <li><a href='https://www.instagram.com/egeneusco/' aria-label='codepen' rel="noreferrer" target="_blank">
                    <div className="icons"><FaInstagram/></div></a>
                </li>
                <li><a href='https://www.youtube.com/channel/UCZ-2WEEURgQZ4MEOIlFLjbQ' aria-label='codepen' rel="noreferrer" target="_blank">
                    <div className="icons"><FaYoutube/></div></a>
                </li>
            </ul>
            <div className='whatsapp-icon position-fixed'>
                <button 
                    onClick={handleClick} 
                    className={`showButton ${RotateClass}`} 
                >
                    <img src={Logo} alt="footer" height= "100%" width="100%" className="d-block image-fluid w-100" loading="lazy"/>
                </button>
                <a className= {`codepen showIcon ${CodepenClass}`} href='https://codepen.io/egenius1243' aria-label='codepen' rel="noreferrer" target="_blank">
                    <FaCodepen color="#ff3c41" />
                </a>
                <a className= {`showIcon ${LinkedinClass}`} href='https://www.linkedin.com/in/mohammad-husnain789' aria-label='codepen' rel="noreferrer" target="_blank">
                    <FaLinkedin color="#0072b1" />
                </a>
                <a className= {`showIcon ${SkypeClass}`} href='skype:live:.cid.b4dbaeeacb56ba30?chat' aria-label='codepen' rel="noreferrer" target="_blank">
                    <FaSkype color="#00AFF0" />
                </a>
                <a className= {`showIcon ${UpworkClass}`} href='https://www.upwork.com/freelancers/~01dfa1b4241fc34c91' aria-label='codepen' rel="noreferrer" target="_blank">
                    <FontAwesomeIcon icon={faUpwork} color='#108A00' />
                </a>
            </div>
        </div>
    )
}