import {BlogsItems} from '../Items/BlogsItems'
import { Link } from "react-router-dom";
import React, { useState } from 'react';

export const BlogCards = () => {
    const itemsPerPage = 6;
    const [currentPage, setCurrentPage] = useState(1);
    const filteredProducts = BlogsItems;
    const paginatedData = filteredProducts.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
    const handlePageChange = (page) => {
        if (page > 0 && page <= Math.ceil(filteredProducts.length / itemsPerPage)) {
            setCurrentPage(page);
        }
    };

    return(
        <div className="container p-0">
            {/* <div className="Adcard-container row m-auto pt-4 pt-lg-5 d-none d-lg-block">   
                <h2 className="h1 mb-5 text-center text-dark">Our Latest Blogs</h2>
                {
                    paginatedData.slice(0, 1).map((data, index) => (
                        <div key={index} className='col-8 m-auto mb-3'>
                            <Link to={`/blogs/${data.slug}`} className="link text-decoration-none text-white">
                                <div className="card recent-card overflow-hidden text-center rounded shadow bg-transparent text-dark border-0">
                                    <figure className="snip1529 mb-0">
                                        <div className="image-container">
                                            <img src={data.image} alt='blogs' height="100%" width="100%" className="m-auto d-block img-fluid w-100 h-100" loading="lazy" />
                                        </div>
                                        <figcaption className='w-100'>
                                            <h2 className='h2 mb-4'>{data.title}</h2>
                                            <div className='d-flex justify-content-between'>
                                                <p className="p">{data.author}</p>
                                                <p className="p">{data.date}</p>
                                            </div>
                                        </figcaption>
                                    </figure>
                                </div>
                            </Link>
                        </div>
                    ))
                }
            </div>   */}
            <div className="Adcard-container row m-auto pt-4 pt-lg-5">   
                <h2 className="h2 mb-5 text-center text-dark">Our Popular Blogs</h2>
                {
                     BlogsItems.length > 0 ? (
                        paginatedData.map((data, index) => (
                            <div key={index} className='col-lg-6 mb-3'>
                                <Link to={`/blogs/${data.slug}`} className="link text-decoration-none text-white">
                                    <div className="card overflow-hidden text-center rounded bg-transparent text-dark border-0">
                                        <figure className="snip1529">
                                            <div className="image-container">
                                                <img src={data.image} alt='blogs' height="100%" width="100%" className="m-auto d-block img-fluid w-100 h-100" loading="lazy" />
                                            </div>
                                            <figcaption>
                                                <h2 className='h2 mb-4'>{data.title}</h2>
                                                <div className='d-flex justify-content-between'>
                                                    <p className="p">{data.author}</p>
                                                    <p className="p">{data.date}</p>
                                                </div>
                                            </figcaption>
                                        </figure>
                                    </div>
                                </Link>
                            </div>
                        ))
                    ) : (
                        <h3 className='text-dark'>No Blogs found.</h3>
                    )
                }
                {
                    Math.ceil(BlogsItems.length) > itemsPerPage ? (
                        <div className='Paginated-Button d-flex flex-row justify-content-center align-items-center gap-4 py-4'>
                            <button onClick={() => handlePageChange(currentPage - 1)} className='products_btn'>Prev</button>
                            <span className='text-dark'>Page {currentPage} of {Math.ceil(filteredProducts.length / itemsPerPage)} </span>
                            <button onClick={() => handlePageChange(currentPage + 1)} className='products_btn'>Next</button>
                        </div>
                    ):(
                        <></>
                    )
                }
            </div>  
        </div>
    )
}