import React from 'react';
import { AppLayout } from "./layout/AppLayout";

function App() {
  return (
    <div className='site-container'> 
      <AppLayout />
    </div>
  );
}
export default App;
