// withLayout.js
import React from 'react';
import { Navbar } from "../../view/Navbar";
import { Footer } from "../../view/Footer";

const withLayout = (WrappedComponent) => {
  return () => (
    <>
      <Navbar/>
      <WrappedComponent/>
      <Footer/>
    </>
  );
};

export default withLayout;