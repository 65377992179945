import { useState } from "react";
import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom'; 
import emailjs from '@emailjs/browser';

export const ContactUsForm = () => {
    const form = useRef();
    const navigate = useNavigate();
    const FormType = 'ContactForm';
    const initialUser = {
        FirstName: '',
        LastName: '',
        Phone: '',
        Email: '',
        Message: '',
    };
    const [formData, setFormData] = useState(initialUser);
    const [Fname, setFName] = useState(formData.FirstName);
    const [Lname, setLName] = useState(formData.LastName);
    const [Phone, setPhoneNumber] = useState(formData.Phone);
    const [Email, setEmail] = useState(formData.Email);
    const [Message, setMessage] = useState(formData.Message);

    const sendEmail = async (e) => {
        e.preventDefault();
        setFormData({Fname: Fname,Lname: Lname,Phone: Phone,Email: Email,Message: Message,FormType: FormType });
        const option = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                Fname, Lname, Phone, Email, Message, FormType
            })
        };

        const res = await fetch(`${process.env.REACT_APP_FIREBASE_DATABASE_URL}/ContactUs-Form-Entries.json`, option);
        if (res.ok) {
            emailjs.sendForm('service_fn3vix4', 'template_uz9z7nr', form.current, '-bWN5Fc81jOgMZsJs')
            .then((result) => {
                navigate('/Form-Submited');
                setFName('')
                setLName('')
                setPhoneNumber('')
                setEmail('')
                setMessage('')
            }, (error) => {
                alert(error);
            });
        }
    };

    return (
        <div className="contact-wrap w-100 p-md-5 p-3 rounded login-box">
            <form method="POST" ref={form} onSubmit={sendEmail}>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group ">
                            <label htmlFor="FName" className="text-white mb-2">First NAME</label>
                            <input
                                type="text"
                                placeholder="First Name"
                                className="form-control mb-2"
                                id="FName"
                                value={Fname}
                                name="First_Name"
                                autoComplete="Don"
                                onChange={(e) => setFName(e.target.value)}
                                required
                            />
                        </div>
                    </div>

                    <div className="col-md-6 mb-3">
                        <div className="form-group">
                            <label htmlFor="LName" className="text-white mb-2">Last Name</label>
                            <input
                                type="text"
                                placeholder="Last Name"
                                className="form-control mb-2"
                                id="LName"
                                value={Lname}
                                name="Last_Name"
                                autoComplete="Jon"
                                onChange={(e) => setLName(e.target.value)}
                                required
                            />
                        </div>
                    </div>

                    <div className="col-md-12 mb-3">
                        <div className="form-group">
                            <label htmlFor="phone" className="text-white mb-2">Phone Number</label>
                            <input
                                type="number"
                                placeholder="***********"
                                className="form-control mb-2"
                                id="phone"
                                value={Phone}
                                name="phone"
                                autoComplete="***********"
                                onChange={(e) => setPhoneNumber(e.target.value)}
                                required
                            />
                        </div>
                    </div>

                    <div className="col-md-12 mb-3">
                        <div className="form-group">
                            <label htmlFor="email" className="text-white mb-2">Email</label>
                            <input
                                type="email"
                                placeholder="test@gmail.com"
                                className="form-control mb-2"
                                id="email"
                                value={Email}
                                name="email"
                                autoComplete="test@gmail.com"
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </div>
                    </div>

                    <div className="col-md-12 mb-5">
                        <div className="form-group">
                            <label htmlFor="message" className="text-white mb-2">MESSAGE</label>
                            <textarea
                                type="text"
                                placeholder="Enter Message"
                                value={Message}
                                name="message"
                                className="form-control mb-2"
                                id="message"
                                cols="30"
                                rows="6"
                                onChange={(e) => setMessage(e.target.value)}
                                required
                            ></textarea>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group">
                            <input
                                type="hidden"
                                value={FormType}
                                name="FormType"
                            />
                        </div>
                    </div>

                    <div className="col-md-12">
                        <div className="form-group d-flex justify-content-center">
                            <button type="submit" className="w-50 bg-transparent border-0">
                                <div className="d-block py-3 text-center m-auto anim-button">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    Submit
                                </div>
                            </button>
                            <div className="submitting sf-hidden"></div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};