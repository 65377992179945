import { useEffect } from "react";
import { Advisory } from "../component/Advisory/Advisory";
import { HomeAccordian } from "../component/HomeComponent/HomeAccordian";
import { HomeService } from "../component/HomeComponent/HomeService";
import { HomeSlider } from "../component/HomeComponent/HomeSlider";
import { Testimonials } from "../component/Testimonial/Testimonials";
import { VisionStrategy } from "../component/HomeComponent/VisionStrategy";
import ScrollToTop from '../component/ScrollToTop/ScrollToTop';
import withLayout from '../component/withLayout/withLayout';

const Home = () => {
  ScrollToTop();

  useEffect(() => {
    const link = document.createElement('link');
    link.rel = 'canonical';
    link.href = 'https://egeneus.co';
    document.head.appendChild(link);
    return () => {
      document.head.removeChild(link);
    };
  }, []);

  return (
    <div id="home-page" className="Home-wrapper">
      <h1 className="d-none">Egeneus: Home Page</h1>
      <HomeSlider/>
      <Advisory/>
      <VisionStrategy/>
      <HomeService/>
      <HomeAccordian/>
      <Testimonials/>
    </div>
  );
};
export default withLayout(Home);