import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import ScrollToTop from '../component/ScrollToTop/ScrollToTop';
import withLayout from '../component/withLayout/withLayout';

const NotFound = () => {
  ScrollToTop();
  const navigate = useNavigate();
  const [countdown, setCountdown] = useState(() => 5);

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/');
    }, 5000);
    return () => clearTimeout(timer);
  }, [navigate]);

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);
    return () => clearInterval(timer);
  }, [countdown]);

  return (
    <div id="page_not_found" className="page_not_found_Wrapper py-4 py-lg-5" style={{height:'600px'}}>
      <div className="container h-100">
        <div className="row align-items-center h-100">
          <div className="col-12">
            <h1 className="h1 text-dark text-center mb-3">404: Page Not Found</h1>
            <h2 className="text-dark text-center mb-4">The page you are looking for does not exist.</h2>
            <p className="p text-dark text-center">You will be <span style={{color: "#7BA447" , fontWeight: "500"}}>Redirected To The Home Page</span> in {countdown} seconds.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withLayout(NotFound);