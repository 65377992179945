import React , {useEffect} from "react";
import { useParams } from 'react-router-dom';
import {BlogsItems} from '../Items/BlogsItems'
import ScrollToTop from '../ScrollToTop/ScrollToTop';
import withLayout from '../../component/withLayout/withLayout';

const BlogDetails = () => {
  ScrollToTop();
    const { BlogName } = useParams();
    const blogs_detail = BlogsItems.find(BlogsItems => `${BlogsItems.slug}` === BlogName);
    useEffect(() => {
      const link = document.createElement('link');
      link.rel = 'canonical';
      link.href = `https://egeneus.co/blogs/${blogs_detail.slug}`;
      document.head.appendChild(link);
      return () => {
        document.head.removeChild(link);
      };
    }, [blogs_detail.slug]);
    return (
      <div className="Product-details pt-4 pt-lg-5">
        <div className="container py-5">
          {blogs_detail ? (
            <div className="row align-items-center">
              <h1 className="h1 text-dark mb-5">{blogs_detail.title}</h1>
              <div className="col-lg-10 image-container mb-5 m-auto">
                <img src={blogs_detail.image} alt='blogs Details' height="100%" width="100%" className="m-auto d-block img-fluid w-100 h-100" loading="lazy" />
              </div>
              <p className="p text-dark blog-details-desc" dangerouslySetInnerHTML={{ __html: blogs_detail.description}}></p> 
            </div>
          ) : (
            <p className="text-center text-dark">Product not found.</p>
          )}
        </div>
      </div>
    );
}
export default withLayout(BlogDetails)