import { useEffect } from "react";
import { Link } from "react-router-dom";
import ScrollToTop from '../component/ScrollToTop/ScrollToTop';
import withLayout from '../component/withLayout/withLayout';

const PrivacyPolicy = () => {

    useEffect(() => {
		const link = document.createElement('link');
		link.rel = 'canonical';
		link.href = 'https://egeneus.co/privacy-policy';
		document.head.appendChild(link);
		return () => {
		  document.head.removeChild(link);
		};
	}, []);

    ScrollToTop();
    return(
        <div id="privacy-policy" className="wrapper privacy-policy py-4 py-lg-5 my-lg-5">
            <div className="container">
                <h1 className="h1 mb-5 text-dark">Privacy Policy of EgeneusCo.</h1>
                <h2 className="h2 text-dark">1. Introduction</h2>
                <p className="p text-dark">This privacy policy applies to the EgeneusCo Website for all devices, developed by EgeneusCo Team. This Website helps you find the best design for your business portfolio & E-commerce Sites.</p>
                <h2 className="h2 text-dark">2. Security</h2>
                <p className="p text-dark">We are committed to protecting your privacy. We utilize industry-standard security measures to safeguard any information you provide through the Application.</p>
                <h2 className="h2 text-dark">3. Children's Privacy</h2>
                <p className="p text-dark">Our Website may be used by children under 13. We do not knowingly collect personal information from children under 13. If you are a parent or guardian and you become aware that your child has provided us with personal information, please contact us at <a className="a text-decoration-underline text-dark" href="mailto:EgeneusCo@outlook.com" rel="noreferrer" aria-label='mail'>EgeneusCo@outlook.com</a>. We will take steps to remove such information from our servers.</p>
                <h2 className="h2 text-dark">4. Changes to This Privacy Policy</h2>
                <p className="p text-dark">EgeneusCo may update this privacy policy from time to time. We will notify you of any changes by posting the new privacy policy on this page. You are advised to periodically review this privacy policy for any changes</p>
                <h2 className="h2 text-dark">6. Contact Information</h2>
                <p className="p text-dark">For any questions regarding these terms, please visit our <Link to='/contact-us' className="text-dark text-decoration-underline">Contact Us</Link> page.</p>
            </div>
        </div>
    )
}
export default withLayout(PrivacyPolicy)