import { ServiceCards } from "../../component/Cards/ServiceCards.jsx"

export const OurService = () => {
    return(
        <div className="services-section py-2 py-lg-5 bg-light">
            <div className="container p-0">
                <h2 className="h2 text-center text-dark">Our Related Services</h2>
                <ServiceCards/>
            </div>
        </div>
    )
}