import { HomeServiceCards } from "../Cards/HomeServiceCards"
import { Link } from "react-router-dom";

export const HomeService = () => {
    return(
        <div className="Service-Home-Section py-4 py-lg-5 overflow-hidden">
            <div className="container p-0">
                <div className="Service-Home-Container">
                    <div className="row px-4">
                        <div className="col-12 text-center">
                            <h2 className="text-dark fade-out">Advisory & Consulting Services</h2>
                            <p className="text-dark mb-0 text-center">We offer strategic insights and expert guidance to help you achieve your goals and drive sustainable growth.</p>
                            <p className="text-dark text-center">Whether you're seeking market analysis, operational efficiency improvements our team is here to support your success.</p>
                        </div>
                    </div>
                    <HomeServiceCards/>
                </div>
            </div>
            <div className="text-center text-decoration-none m-auto mt-lg-4 py-2 ">
                  <Link to="/services" className="link btn btn-primary form-button">View More</Link>
            </div>
        </div>
    )
}