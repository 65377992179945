import React , {useEffect} from "react";
import { useParams, useNavigate } from 'react-router-dom';
import {products} from '../Items/ProductItems'
import ScrollToTop from '../ScrollToTop/ScrollToTop';
import withLayout from '../../component/withLayout/withLayout';

const ProductDetails = () => {
  ScrollToTop();
    const { Productname } = useParams();
    const navigate = useNavigate();
    const product_detail = products.find(product => `${product.type}-Application-${product.name}` === Productname);
    
    useEffect(() => {
      const link = document.createElement('link');
      link.rel = 'canonical';
      link.href = `https://egeneus.co/products/${product_detail.type}-Application-${product_detail.name}`;
      document.head.appendChild(link);
      return () => {
        document.head.removeChild(link);
      };
    }, [product_detail.name, product_detail.type]);
    
    const handleClick = () => {
      navigate('/product-form', { state: { data: product_detail } });
    };
    return (
      <div className="Product-details pt-4 pt-lg-5">
        <div className="container py-5">
          {product_detail ? (
            <div className="row align-items-center">
                <div className="col-12 col-md-6 mb-4">
                    <div className="image-container">
                      <div id="carouselExampleIndicators" className="carousel slide">
                        <div className="carousel-inner">
                          {
                            product_detail.image.map((image, index) => (
                              <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                                  <img src={image} alt={image} className="m-auto d-block img-fluid w-100 h-100" height="100%" width="100%" loading="lazy"/>
                              </div>
                            ))
                          }
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                      </div>
                    </div>
                </div>
                <div className="col-12 col-md-6">
                    <div className="d-flex justify-content-between align-items-end">
                      <h1 className="text-dark mb-0">{product_detail.name}</h1>
                      <h2 className="text-dark mb-3">${product_detail.price}</h2>
                    </div>
                    <h3 className="text-dark mb-3">{product_detail.shortdescription}</h3>
                    <p className="text-dark">{product_detail.description}</p>
                    <p className="text-dark">View Full Site  
                        <a href={product_detail.link} rel="noreferrer" aria-label={product_detail.name} target="_blank" style={{ color: "#7BA447"}} className="ms-1">
                          More
                        </a>
                    </p>
                    <div className="d-flex gap-3">
                        <p className="text-dark">Author: {product_detail.author}</p>
                        <p className="text-dark">Type: {product_detail.type}</p>
                    </div>
                    <div className="text-decoration-none py-2">
                      <button onClick={handleClick} className="link btn btn-primary form-button w-50 ">Get Now</button>
                    </div>
                </div>
            </div>
          ) : (
            <p className="text-center text-dark">Product not found.</p>
          )}
        </div>
      </div>
    );
}

export default withLayout(ProductDetails)