import { Routes, Route } from "react-router-dom";
import Home from "../pages/Home";
import AboutUs from "../pages/AboutUs";
import Services from "../pages/Services"
import Projects from "../pages/Projects"
import ContactUs from "../pages/ContactUs"
import FormSubmitted from '../pages/FormSubmitted';
import Products from '../pages/Products';
import ProductDetails from '../component/Product/ProductDetails';
import ProductForm from '../component/Product/ProductForm';
import ProductFormSubmitted from '../component/Product/ProductFormSubmitted';
import TermsCondition from '../pages/TermsCondition';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import Blogs from "../pages/Blogs";
import BlogDetails from "../component/BlogComponent/BlogDetails";
import TitleUpdater from "../component/TitleUpdaterComponent/TitleUpdater";
import NotFound from "../pages/NotFound";
import Register from "../pages/Register";
import Login from "../pages/Login";
import ForgetPassword from "../pages/ForgetPassword";

export const AppRoutes = () => {
  return (
  
    <div>
      <Routes>
        <Route path="/login" element={<TitleUpdater title="EgeneusCo: Login page" description=""><Login/></TitleUpdater>}/>
        <Route path="/registration" element={<TitleUpdater title="Register Yourself @EgeneusCo to get a best design for your webiste." description="Discover a lot of Web design that are beneficial for your business platform."><Register/></TitleUpdater>}/>
        <Route path="/password-reset" element={<TitleUpdater title="EgeneusCo: reset page" description=""><ForgetPassword/></TitleUpdater>}/>
        
        <Route path="/blogs" element={<TitleUpdater title="The Power of Collaboration: How Startups Can Grow Together with EgeneusCo" description="Unlock collaborative growth with EgeneusCo! Leverage shared resources, expertise, and networking to elevate your startup's success."><Blogs/></TitleUpdater>}/>
        <Route path="/blogs/:BlogName" element={<TitleUpdater title="EgeneusCo News & Insights: Stay Ahead of the Curve" description="Stay up-to-date with the latest EgeneusCo news, industry trends, and expert insights to drive your startup's growth and success."><BlogDetails/></TitleUpdater>}/>
        <Route path="/" element={<TitleUpdater title="EgeneusCo - Empowering Businesses with Cutting-Edge Technology Solutions" description="EgeneusCo: A collaborative platform for startups, fueled by innovation, mentorship, and cutting-edge tech, empowering entrepreneurs."><Home/></TitleUpdater>}/>
        <Route path="/about-us" element={<TitleUpdater title="Our Mission: Fueling the Future of Entrepreneurship" description="EgeneusCo empowers entrepreneurs and drives innovation by fostering collaborative growth and community in the startup ecosystem."><AboutUs/></TitleUpdater>}/>
        <Route path="/services" element={<TitleUpdater title="Innovate, Collaborate, Succeed: EgeneusCo Services" description="Innovate with our expert guidance, collaborate with industry peers, and succeed with our comprehensive services, tailored to startup growth and success."><Services/></TitleUpdater>}/>
        <Route path="/projects" element={<TitleUpdater title="Explore Our Projects and Success Stories" description="Dive into our portfolio of innovative projects, showcasing our expertise and the impact we've made for our clients, driving growth and success together."><Projects/></TitleUpdater>}/>
        <Route path="/contact-us" element={<TitleUpdater title="Contact EgeneusCo today to explore our services!" description="Ready to discuss your project or idea? Our team is here to help. Fill out the form or give us a call to get started today!"><ContactUs/></TitleUpdater>}/>
        <Route path="/products" element={<TitleUpdater title="EgenesuCo's Product Suite: Elevate Your Business" description="Discover our cutting-edge products that streamline operations, boost efficiency, and drive growth to elevate your business to new heights!"><Products/></TitleUpdater>}/>
        <Route path="/products/:Productname" element={<TitleUpdater title="Explore Our Product Features and Technical Specifications" description="Discover the features, specs, and benefits that set our product apart from the competition, including its unique selling points and technical advantages."><ProductDetails/></TitleUpdater>}/>
        <Route path="/product-form" element={<TitleUpdater title="Configure Your Product: Options and Pricing" description="Create your ideal product using our configuration tool—explore features, options, and pricing to find the perfect solution for your needs!"><ProductForm/></TitleUpdater>}/>
        <Route path="/Product-form-submited" element={<TitleUpdater title="Product Configuration Submitted: Our Team Will Review Your Request" description="We appreciate your submission. Our team will carefully review your product configuration and provide a detailed quote, including pricing and product specifications"><ProductFormSubmitted/></TitleUpdater>}/>
        <Route path="/form-submited" element={<TitleUpdater title="Submission Successful: We'll Be in Touch Soon" description="We've received your message. Our team will review your submission and be in touch soon with a response, answer, or solution to your question or feedback."><FormSubmitted/></TitleUpdater>}/>
        <Route path="/terms-conditions" element={<TitleUpdater title="Legal Information: Terms and Conditions for Use." description="Read our terms of service, conditions to understand your rights and responsibilities when using our website or services."><TermsCondition/></TitleUpdater>}/>
        <Route path="/privacy-policy" element={<TitleUpdater title="Our Commitment to Your Privacy: Your data is safe with us." description="Our privacy policy outlines our commitment to protecting your data. Read more about our practices and procedures."><PrivacyPolicy/></TitleUpdater>}/>
      
        <Route path="*" element={<TitleUpdater title="EgeneusCo: Not Found Page" description=""><NotFound/></TitleUpdater>}/>

      </Routes>
    </div>
  );
};

