import {products} from '../Items/ProjectsItems'
import button_view from '../../asserts/button-view.webp'

export const HomeProjectsCards = () => {
    
    return(
        <div className="Adcard-container row m-auto pt-4 pt-lg-5 d-flex justify-content-center justify-content-lg-start">
            {products.slice(0, 6).map((data, index) => (
                <div key={index} className='col-6 col-md-6 col-lg-4 mb-3'>
                    <a href={data.link} aria-label={data.name}  rel="noreferrer" target='_blank' className='text-decoration-none'>
                        <div className="card overflow-hidden text-center rounded bg-transparent text-dark border-0">
                            <figure className="snip1529">
                                <div className="image-container">
                                    <img src={data.image} height="100%" width="100%" alt={data.name + "image"} className="m-auto d-block img-fluid w-100 h-100" loading="lazy"/>
                                </div>
                                <div className="date"><span className="day text-dark mb-2">{data.price}$</span><span className="month text-dark">Price</span></div>
                                <figcaption>
                                    <h3>{data.name}</h3>
                                    <p className='text-start'>{data.description}</p>
                                </figcaption>
                                <div className="hover">
                                    <img src={button_view} height="30%" width="30%" alt='button view' loading="lazy"/>
                                </div>
                            </figure>
                        </div>
                    </a>
                </div>
            ))}
        </div>    
    )
}