import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

const ProductFormSubmitted = () => {
  const navigate = useNavigate();
  const [countdown, setCountdown] = useState(() => 5);

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/products');
    }, 5000);
    return () => clearTimeout(timer);
  }, [navigate]);


  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);
    return () => clearInterval(timer);
  }, [countdown]);

  return (
    <div id="Form-Submited" className="Form-Wrapper py-4 py-lg-5" style={{height:'600px'}}>
        <div className="container h-100">
          <div className="row align-items-center h-100">
            <div className="col-12">
              <h1 className="h1 text-dark text-center mb-3">Form Submit Successfully!</h1>
              <h3 className="text-dark text-center mb-4">Thank's you for Your Preference & buy products from us.</h3>
              <p className="p text-dark text-center">You will be <span style={{color: "#7BA447" , fontWeight: "500"}}>Redirected To The Products Page</span> in {countdown} seconds.</p>
            </div>
          </div>
        </div>
    </div>

  );
};

export default ProductFormSubmitted
