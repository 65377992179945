import React, { useEffect } from 'react';

const TitleUpdater = ({ title, description, children }) => {
  useEffect(() => {
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute('content', description);
    }
    document.title = title;
  }, [title , description]);

  return <>{children}</>;
};

export default TitleUpdater;