import { useEffect , useState } from "react";
import React, { useRef } from 'react';
import { Link } from "react-router-dom";
import registerback from "../asserts/service_banner.webp"

const Login = () => {

	useEffect(() => {
		const link = document.createElement('link');
		link.rel = 'canonical';
		link.href = 'https://egeneus.co/login';
		document.head.appendChild(link);
		return () => {
		  document.head.removeChild(link);
		};
	}, []);

    const form = useRef();
    const FormType = 'LoginForm';
    const initialUser = {
        FirstName: '',
        Phone: '',
        Email: '',
        Password: '',
        RePassword: '',
    };
    const [formSubmitting, setFormSubmitting] = useState(false);
    const [formMessage, setFormMessage] = useState('');
    const [formData, setFormData] = useState(initialUser);
    const [Email, setEmail] = useState(formData.Email);
    const [Password, setPassword] = useState(formData.Password);

    const submitForm = async (e) => {
        e.preventDefault();
        setFormSubmitting(true);
        setFormMessage({formMessage : 'Submitted'})
        setFormData({Email: Email, Password: Password, FormType: FormType});
        console.log(Email, Password, FormType);
    }

    return (
        <div className="register_section bg-dark">
			<div className="container-fluid p-0">
				<div className="register_container d-flex align-items-center justify-content-center position-relative"> 
					<div className="background_image position-absolute top-0 h-100 w-100">
						<img src={registerback} alt="" height="100%" width="100%" className="" />
					</div>
					<div className="Regiter_card">
						<div className="Register-form w-100 p-md-5 p-3 rounded login-box">
							<div className={`row d-flex justify-content-center ${formSubmitting === false ? '' : 'loading'}`}>
								<div className="col-10 col-md-8 col-xl-5 register-card p-4 p-lg-5">
									<h1 className="h1 text-center mb-4 mb-lg-5">Login</h1>
									<form method="POST" ref={form} onSubmit={submitForm}>
										<div className="row">
											<div className="col-12 mb-3">
												<div className="form-group">
													<input
														type="email"
														placeholder="Enter Your Email"
														className="form-control mb-2 text-center text-light bg-transparent border-0 border-bottom rounded-0 p-2 p-lg-3"
														id="email"
														value={Email}
														name="email"
														autoComplete="test@gmail.com"
														onChange={(e) => setEmail(e.target.value)}
														required
													/>
												</div>
											</div>
											<div className="col-12 mb-4">
												<div className="form-group">
													<input
														type="password"
														placeholder="Enter Your Password"
														className="form-control mb-2 text-center text-light bg-transparent border-0 border-bottom rounded-0 p-2 p-lg-3"
														id="password"
														value={Password}
														name="password"
														onChange={(e) => setPassword(e.target.value)}
														required
													/>
												</div>
											</div>
											<div className="col-12 mb-4">
											<p className="p text-center d-flex align-items-center justify-content-end gap-2"><Link to='/password-reset' className="m-0 p-0 login-link">Forget Password?</Link></p>
												<div className="form-group">
													<input
														type="hidden"
														value={FormType}
														name="FormType"
													/>
												</div>
											</div>
											<div className="col-12 mb-4">
												<div className="form-group d-flex justify-content-center mb-4">
													<button type="submit" className="w-100 bg-transparent border-0">
														<div className="d-block py-3 text-center m-auto anim-button text-light">
															<span></span>
															<span></span>
															<span></span>
															<span></span>
															Submit
														</div>
													</button>
													<div className="submitting sf-hidden"></div>
												</div>
												<p className={`p text-center ${formMessage? 'submitted-success' : formMessage? 'SubmittedError' : 'd-none'}`}>
													{formMessage? 'Form Has Been Submit Succesfully' : formMessage? 'Form Has Not Submit Succesfully' : ''}</p>
											</div>
											<div className="col-12">
												<p className="p text-center d-flex align-items-center justify-content-center gap-2">Didn't have an account? <Link to='/registration' className="m-0 p-0 login-link">Register</Link></p>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
        </div>
    );
};
export default Login